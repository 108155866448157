import React, { useState } from 'react';
import { Box, Typography, CardMedia } from '@mui/material';

const PropertyList = ({ geoJson, center, setCenter }) => {
    const [selectedProperty, setSelectedProperty] = useState(null);

    // Check if geoJson exists and has features before destructuring
    if (!geoJson || !geoJson.features) {
        return <Typography>No properties available.</Typography>; // You can render something if no data is present
    }

    const { features } = geoJson;

    const handleSelectProperty = (id) => {
        setSelectedProperty(id);
        setCenter(id);
        console.log("Centering on property", id);
    };

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            {features.map((feature, index) => {
                const isSelected = selectedProperty === feature.id;
                const isCentered = center === feature.id;
                const { name, description, price, score, images, size } = feature.properties;
                console.log(feature)
                return (
                    <Box
                        key={feature.id || index}
                        onClick={() => handleSelectProperty(feature.id)}
                        sx={{
                            padding: 2,
                            border: '1px solid #ccc',
                            borderRadius: 2,
                            cursor: 'pointer',
                            backgroundColor: isSelected ? '#f5f5f5' : (isCentered ? '#e0f7fa' : '#ffffff'),
                            transition: 'background-color 0.3s ease, max-height 0.7s ease',
                            overflow: 'hidden',
                            maxHeight: isSelected ? '500px' : '200px',
                        }}
                    >
                        <Typography variant={isSelected ? 'h4' : 'h6'}>
                            {name}
                        </Typography>
                        <Typography variant="body2">{description}</Typography>
                        <Typography variant="body2"><strong>Price:</strong> ${price}</Typography>
                        <Typography variant="body2"><strong>Score:</strong> {score}</Typography>
                        {size && <Typography variant="body2"><strong>Size:</strong> {size}</Typography>}
                        {isSelected && (
                            <CardMedia
                                component="img"
                                height="200"
                                src={images[0]}
                                alt={name}
                                sx={{
                                    borderRadius: 2,
                                    marginTop: 2,
                                    animation: 'fadeIn 0.5s ease-in-out',
                                    '@keyframes fadeIn': {
                                        from: { opacity: 0 },
                                        to: { opacity: 1 },
                                    },
                                }}
                            />
                        )}
                    </Box>
                );
            })}
        </Box>
    );
};

export default PropertyList;
