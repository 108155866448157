import PropertyList from "./components/PropertyList";
import {Paper} from "@mui/material";
import Grid from "@mui/material/Grid";
import MapBox from "./components/MapBox";
import {useTheme} from "@emotion/react";
import {useContext, useState} from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import DashboardMain from "../../layouts/Main/DashboardMain";
import Box from "@mui/material/Box";
import {DataContext} from "../../DataContext";

const Dashboard = () => {
    const {data} = useContext(DataContext);
    const theme = useTheme();
    const [center, setCenter] = useState();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <DashboardMain>
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                minHeight="90vh"
                width={1}
                sx={{
                    backgroundColor: theme.palette.background.default,
                    padding: isMd ? '1rem 2rem 2rem' : '0.5rem 1rem 1rem',
                }}
            >
                <Grid container spacing={isMd ? 2 : 1} alignItems="stretch">
                    <Grid item xs={12} md={8}>
                        <Paper
                            elevation={3}
                            sx={{
                                borderRadius: 2,
                                overflow: 'hidden',
                                padding: 0,
                                height: '85vh',
                            }}
                        >
                            <MapBox points={data} center={center} setCenter={setCenter} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                padding: 3,
                                borderRadius: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '85vh',
                            }}
                        >
                            <PropertyList geoJson={data} center={center} setCenter={setCenter} />
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </DashboardMain>
    );
};

export default Dashboard;
