/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { alpha } from '@mui/material/styles';
import { colors } from '@mui/material';

const mock = [
    {
        color: colors.lightBlue[500],
        title: 'Your Preferences',
        subtitle: "Tell us what you're looking for, we'll do all the work",
        icon: (
            <svg
                height={24}
                width={24}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
            </svg>
        ),
    },
    {
        color: colors.yellow[500],
        title: 'Our Algorithm',
        subtitle: 'We take into account the things that matter',
        icon: (
            <svg
                height={24}
                width={24}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                />
            </svg>
        ),
    },
    {
        color: colors.indigo[500],
        title: 'Your Budget',
        subtitle: 'We make sure your get what you want at the price you want',
        icon: (
            <svg
                height={24}
                width={24}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
            </svg>
        ),
    },
    {
        color: colors.deepPurple[500],
        title: 'Our Personalization',
        subtitle: 'We factor everything into the cost of living, including your lifestyle',
        icon: (
            <svg
                height={24}
                width={24}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                />
            </svg>
        ),
    },
];

const Masonry = () => {
    const theme = useTheme();

    const LeftSide = () => (
        <Grid container spacing={4}>
            {mock.map((item, index) => (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    key={index}
                    data-aos="fade-up"
                    data-aos-delay={index * 100}
                    data-aos-offset={100}
                    data-aos-duration={600}
                >
                    <Box
                        display={'block'}
                        width={1}
                        sx={{
                            transform: index % 2 === 1 ? { md: 'translateY(80px)' } : 'none',
                        }}
                    >
                        <Box component={Card} padding={4} borderRadius={2} width={1}>
                            <Box display={'flex'} flexDirection={'column'}>
                                <Box
                                    component={Avatar}
                                    width={60}
                                    height={60}
                                    marginBottom={2}
                                    bgcolor={alpha(item.color, 0.1)}
                                    color={item.color}
                                    variant={'rounded'}
                                    borderRadius={2}
                                >
                                    {item.icon}
                                </Box>
                                <Typography
                                    variant={'h6'}
                                    gutterBottom
                                    sx={{ fontWeight: 700 }}
                                >
                                    {item.title}
                                </Typography>
                                <Typography color="text.secondary">{item.subtitle}</Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box marginTop={2} display={'flex'} justifyContent={'flex-end'}>
                                    <Button
                                        endIcon={
                                            <Box
                                                component={'svg'}
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                width={24}
                                                height={24}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                                                />
                                            </Box>
                                        }
                                    >
                                        Learn more
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            ))}
        </Grid>
    );

    const RightSide = () => (
        <Box>
            <Box marginBottom={2}>
                <Typography
                    sx={{
                        textTransform: 'uppercase',
                        fontWeight: 'medium',
                    }}
                    gutterBottom
                    color={'primary'}
                >
                    SEAMLESS HOUSING SOLUTIONS

                </Typography>
            </Box>
            <Box marginBottom={2}>
                <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
                Tailored, cost-effective solutions for every housing need.

                </Typography>
                <Typography color="text.secondary" variant={'h6'}>
                    We'll find the place, just send us your preferences
                    <br />
                </Typography>
            </Box>
            <Grid container spacing={1}>
                {[
                    'Effortlessly match with affordable homes through automated recommendations and personalized services.',
                    'Discover and secure housing that fits your budget and lifestyle with ease.',
                    'Continuous updates and improvements to ensure the best options are always available',
                ].map((item, i) => (
                    <Grid item xs={12} key={i}>
                        <Box component={ListItem} disableGutters width={'auto'} padding={0}>
                            <Box
                                component={ListItemAvatar}
                                minWidth={'auto !important'}
                                marginRight={2}
                            >
                                <Box
                                    component={Avatar}
                                    bgcolor={theme.palette.secondary.main}
                                    width={20}
                                    height={20}
                                >
                                    <svg
                                        width={12}
                                        height={12}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </Box>
                            </Box>
                            <ListItemText primary={item} />
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Box marginTop={4}>
                <Button
                    variant={'contained'}
                    size={'large'}
                    endIcon={
                        <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                        </Box>
                    }
                >
                    Get started
                </Button>
            </Box>
        </Box>
    );

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <LeftSide />
            </Grid>
            <Grid item container alignItems={'center'} xs={12} md={6}>
                <RightSide />
            </Grid>
        </Grid>
    );
};

export default Masonry;
