import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import logo from '../locus.png';

const SidebarNav = ({ pages }) => {
    const theme = useTheme();
    const { mode } = theme.palette;

    const {
        landings: landingPages,
        secondary: secondaryPages,
        company: companyPages,
        account: accountPages,
        portfolio: portfolioPages,
        blog: blogPages,
    } = pages;

    return (
        <Box>
            <Box width={1} paddingX={2} paddingY={1}>
                <Box
                    display={'flex'}
                    component="a"
                    href="/"
                    title="theFront"
                    width={{ xs: 100, md: 120 }}
                >
                    <Box
                        component={'img'}
                        src={logo}
                        height={1}
                        width={1}
                    />
                </Box>
            </Box>
            <Box paddingX={2} paddingY={2}>
                <Box marginTop={1}>
                    <Button
                        size={'large'}
                        variant="outlined"
                        fullWidth
                        component="a"
                        href="/preferences"
                    >
                        Try it Out!
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

SidebarNav.propTypes = {
    pages: PropTypes.object.isRequired,
};

export default SidebarNav;