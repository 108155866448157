import axios from 'axios';
import { Box, Grid, Paper, useTheme, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import Form from './components/Form';
import FormText from './components/FormText';
import LottieAnimation from './components/LottieAnimation';
import Main from '../../layouts/Main';
import Dashboard from '../Dashboard/Dashboard';
import Typography from "@mui/material/Typography";

const FormPage = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'));
    const [error, setError] = useState(null);// Keep track of data from Form

    // Conditionally render Dashboard if data exists, otherwise render the form

    return (
        <Main>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
                width={1}
                sx={{
                    backgroundColor: theme.palette.background.default,
                    padding: isMd ? 2 : 1,
                }}
            >
                <Box
                    elevation={3}
                    sx={{
                        width: '100%',
                        maxWidth: isMd ? '1500px' : '100%',
                        borderRadius: 2,
                        overflow: 'hidden',
                        padding: 0,
                        display: 'flex',
                    }}
                >
                    <Grid container spacing={isMd ? 2 : 1} alignItems="stretch">
                        <Grid item xs={12} md={8}>
                            <Form /> {/* Passing setData to Form */}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{ padding: 2, paddingBottom: 0 }}>
                                <FormText />
                                <LottieAnimation />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Main>
    );
};

export default FormPage;
